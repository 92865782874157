import { AnalysisItem } from './analysis-item';
import { Expose, Type } from 'class-transformer';

class ReportAnalysisItem {
  @Expose()
  public id: number | null;

  @Expose()
  public value: number | string;

  @Expose({ name: 'analysis_item_id' })
  public analysisItemId: number;

  @Expose({ name: 'report_id' })
  public reportId: number;

  @Expose({ name: 'analysis_item' })
  @Type(() => AnalysisItem)
  public analysisItem: AnalysisItem;

  constructor(
    id: number | null,
    value: number,
    analysisItemId: number,
    reportId: number,
    analysisItem: AnalysisItem
  ) {
    this.id = id;
    this.value = value;
    this.analysisItemId = analysisItemId;
    this.reportId = reportId;
    this.analysisItem = analysisItem;
  }
}

export { ReportAnalysisItem };
