import { Report } from '@/models/report';
import { ReportAnalysisItem } from '@/models/report-analysis-item';

interface ReportForm {
  farmer: string;
  field: string;
  area: number | null;
  city: string;
  stateId: number | null;
  cropId: number | null;
  reportAnalysisItems: ReportAnalysisItem[];
}

interface ReportFormToRequest {
  farmer: string;
  field: string;
  area: number | null;
  city: string;
  state_id: number | null;
  crop_id: number | null;
  report_analysis_items: { analysis_item_id: number; value: number | string }[];
}

const getEmptyReportForm = (): ReportForm => ({
  farmer: '',
  field: '',
  area: null,
  city: '',
  stateId: null,
  cropId: null,
  reportAnalysisItems: [] as ReportAnalysisItem[],
});

const getFormByInstance = (
  report: Report,
  reportAnalysisItems?: ReportAnalysisItem[]
): ReportForm => ({
  farmer: report.farmer,
  field: report.field,
  area: report.area,
  city: report.city,
  stateId: report.stateId,
  cropId: report.cropId,
  reportAnalysisItems: reportAnalysisItems || [],
});

const toRequestData = (formData: ReportForm): ReportFormToRequest => {
  return {
    farmer: formData.farmer,
    field: formData.field,
    area: formData.area,
    city: formData.city,
    state_id: formData.stateId,
    crop_id: formData.cropId,
    report_analysis_items: formData.reportAnalysisItems.map((el) => ({
      analysis_item_id: el.analysisItemId,
      value: el.value,
    })),
  };
};

export { getEmptyReportForm, getFormByInstance, toRequestData, ReportForm };
