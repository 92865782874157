import { CapacidadeTampaoP } from '@/models/capacidade-tampao-p';
import { Crop } from '@/models/crop';
import mustache from 'mustache';
import { NivelCriticoP } from '@/models/nivel-critico-p';
import { ReportAnalysisItem } from '@/models/report-analysis-item';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { MeasurementLevel, MeasurementLevelType } from '@/models/measurement-level';

interface CalculatedValues {
  identifier: string;
  value: number | string;
}

interface CustomFunctions {
  [key: string]: (params: { [key: string]: unknown }) => number | string;
}

const calculateValues = (
  items: ReportAnalysisItem[],
  crops: Crop[],
  niveisCriticosP: NivelCriticoP[],
  capacidadesTampaoP: CapacidadeTampaoP[],
  measurementLevels: MeasurementLevel[]
): ReportAnalysisItem[] => {
  const calculated: CalculatedValues[] = items
    .filter((item) => item.analysisItem.type === 'input' || item.analysisItem.type === 'select')
    .map((item) => ({ identifier: item.analysisItem.identifier, value: item.value }));

  items.forEach((item) => {
    calculateValue(
      item,
      items,
      calculated,
      crops,
      niveisCriticosP,
      capacidadesTampaoP,
      measurementLevels
    );
  });

  const result = plainToInstance(ReportAnalysisItem, instanceToPlain(items) as never[]);

  const context = getContext(calculated);

  result.forEach((item) => (item.value = context[item.analysisItem.identifier]));

  return result;
};

const calculateValue = (
  item: ReportAnalysisItem,
  items: ReportAnalysisItem[],
  calculated: CalculatedValues[],
  crops: Crop[],
  niveisCriticosP: NivelCriticoP[],
  capacidadesTampaoP: CapacidadeTampaoP[],
  measurementLevels: MeasurementLevel[]
) => {
  const index = calculated.findIndex((el) => el.identifier === item.analysisItem.identifier);

  if (item.analysisItem.expression !== null && index === -1) {
    const dependencies = getDependencies(item);

    dependencies.forEach((dep) => {
      const depIndex = items.findIndex((depItem) => depItem.analysisItem.identifier === dep);

      if (items[depIndex] === undefined) {
        console.log(dep);
      }

      calculateValue(
        items[depIndex],
        items,
        calculated,
        crops,
        niveisCriticosP,
        capacidadesTampaoP,
        measurementLevels
      );
    });

    let value: number | string = 0;

    const context = getContext(calculated);
    const replacedString = mustache.render(item.analysisItem.expression, context);

    try {
      if (item.analysisItem.type === 'auto') {
        value = eval(replacedString);
      } else if (item.analysisItem.type === 'function') {
        const [functionName, param1, param2, param3, param4, param5] = replacedString.split(',');

        if (customFunctions[functionName]) {
          value = customFunctions[functionName]({
            param1,
            param2,
            param3,
            param4,
            param5,
            crops,
            niveisCriticosP,
            capacidadesTampaoP,
            measurementLevels,
          });
        }
      }
    } catch (e) {
      value = 0;
    }

    calculated.push({ identifier: item.analysisItem.identifier, value });
  }
};

const getDependencies = (item: ReportAnalysisItem): string[] => {
  const findMustacheRegex = new RegExp('{{([a-zA-Z0-9/])*}}', 'g');

  const result = item.analysisItem.expression?.match(findMustacheRegex)?.map((str) => {
    let replaced = str.replace('{{', '');
    replaced = replaced.replace('}}', '');

    return replaced;
  });

  return result || [];
};

const getContext = (calculated: CalculatedValues[]) => {
  const result: { [key: string]: string | number } = {};

  calculated.forEach((el) => (result[el.identifier] = el.value));

  return result;
};

const extracao = (params: { [key: string]: unknown }): number | string => {
  let result = 0;
  const cropName = <string>params.param1;
  const substanceCode = <string>params.param2;
  const crops = <Crop[]>params.crops;

  const index = crops.findIndex((crop) => crop.name === cropName);

  if (index !== -1) {
    result = crops[index].extraction?.find((el) => el.substance.code === substanceCode)?.value || 0;
  }

  return result;
};

const exportacao = (params: { [key: string]: unknown }): number | string => {
  let result = 0;
  const cropName = <string>params.param1;
  const substanceCode = <string>params.param2;
  const crops = <Crop[]>params.crops;

  const index = crops.findIndex((crop) => crop.name === cropName);

  if (index !== -1) {
    result =
      crops[index].exportation?.find((el) => el.substance.code === substanceCode)?.value || 0;
  }

  return result;
};

const nivelCriticoP = (params: { [key: string]: unknown }): number | string => {
  let result = 0;
  const argila = Number.parseFloat(params.param1 as string);
  const extrator = <string>params.param2;
  const niveisCriticosP = <NivelCriticoP[]>params.niveisCriticosP;

  const key = extrator === 'Mehlich-1' ? 'mehlich1' : 'resina';
  const roundedArgila = Math.floor(argila);

  const index = niveisCriticosP.findIndex((nivel) => nivel.argila === roundedArgila);

  if (index !== -1) {
    result = niveisCriticosP[index][key];
  }

  return result;
};

const recomendacaoKCL = (params: { [key: string]: unknown }): number | string => {

  const K2OExtracaoCultura = Number.parseFloat(params.param1 as string);
  const K2OElevacaoCTC = Number.parseFloat(params.param2 as string);

  let value = K2OExtracaoCultura + K2OElevacaoCTC

  if (value < 0) {
    return 0;
  } else {
    return value;
  }
};

const capacidadeTampaoP = (params: { [key: string]: unknown }): number | string => {
  let result = 0;
  const argila = Number.parseFloat(params.param1 as string);
  const extrator = <string>params.param2;
  const capacidadesTampaoP = <CapacidadeTampaoP[]>params.capacidadesTampaoP;

  const key = extrator === 'Mehlich-1' ? 'mehlich1' : 'resina';
  const roundedArgila = Math.floor(argila);

  const index = capacidadesTampaoP.findIndex((nivel) => nivel.argila === roundedArgila);

  if (index !== -1) {
    result = capacidadesTampaoP[index][key];
  }

  return result;
};

const recomendacaoCalagem = (params: { [key: string]: unknown }): number | string => {
  const aplicacaoDeCalcario = <string>params.param1;
  const acrescimoCaO = Number.parseFloat(params.param2 as string);
  const CaO = Number.parseFloat(params.param3 as string);
  const PRNT = Number.parseFloat(params.param4 as string);

  const multiplier = aplicacaoDeCalcario === 'Superficial' ? 1.15 : 1;

  return ((1000 * acrescimoCaO) / (CaO * 10 * (PRNT / 100))) * multiplier;
};

const gessagem = (params: { [key: string]: unknown }): number | string => {
  const saturacaoAl = Number.parseFloat(params.param1 as string);
  const S2040 = Number.parseFloat(params.param2 as string);
  const argila = Number.parseFloat(params.param3 as string);

  if (saturacaoAl > 15 || S2040 < 10) {
    return argila * 50;
  } else {
    return 'Não é necessária';
  }
};

const fertilizacaoS = (params: { [key: string]: unknown }): number | string => {
  const saturacaoAl = Number.parseFloat(params.param1 as string);
  const S020 = Number.parseFloat(params.param2 as string);
  const S2040 = Number.parseFloat(params.param3 as string);
  const extracaoS = Number.parseFloat(params.param4 as string);
  const producaoEsperada = Number.parseFloat(params.param5 as string);

  let value;

  if (!(saturacaoAl > 15 || S2040 < 10)) {
    value = (producaoEsperada / 1000) * extracaoS - (S020 + S2040) * 2;
  } else {
    value = 0;
  }

  if (value < 0){
    value = 0;
  }

  return value;
};

const recomendacaoS = (params: { [key: string]: unknown }): number | string => {
  const saturacaoAl = Number.parseFloat(params.param1 as string);
  const S2040 = Number.parseFloat(params.param2 as string);
  const extracaoS = Number.parseFloat(params.param3 as string);
  const exportacaoS = Number.parseFloat(params.param4 as string);
  const producaoEsperada = Number.parseFloat(params.param5 as string);

  let value;

  if (saturacaoAl > 15 || S2040 < 10) {
    value = (producaoEsperada / 1000) * extracaoS;
  } else {
    value = (producaoEsperada / 1000) * exportacaoS;
  }

  return value;
};


const nivel = (params: { [key: string]: unknown }): number | string => {
  const extractor = <string>params.param1;
  const substanceCode = <string>params.param2;
  const value = Number.parseFloat(params.param3 as string);
  const measurementLevels = <MeasurementLevel[]>params.measurementLevels;

  const levels = measurementLevels.filter((el) => {
    return el.substance.code === substanceCode && el.extractor === extractor;
  });

  return getLevel(levels, value);
};

const getLevel = (levels: MeasurementLevel[], value: number): MeasurementLevelType => {
  for (let i = 0; i < levels.length; i += 1) {
    const level = levels[i];

    if (level.assert(value)) {
      return level.level;
    }
  }

  return 'low';
};

const necessidadeMicronutriente = (params: { [key: string]: unknown }): number | string => {
  const substanceCode = <string>params.param1;
  const extracao = Number.parseFloat(params.param2 as string);
  const exportacao = Number.parseFloat(params.param3 as string);
  const nivel = <MeasurementLevelType>params.param4;
  const producaoEsperada = Number.parseFloat(params.param5 as string);

  if(substanceCode === 'Fe'){
    return nivel === 'low'
      ? (producaoEsperada / 1000) * exportacao
      : 0;
  }

  /* if(substanceCode === 'N' && extracao != 50.62){
    return (producaoEsperada / 1000) * extracao
  } */

  return nivel === 'low' || nivel === 'mid'
    ? (producaoEsperada / 1000) * extracao
    : (producaoEsperada / 1000) * exportacao; 

};

const necessidadeMicronutrienteN = (params: { [key: string]: unknown }): number | string => {
  const extracao = Number.parseFloat(params.param1 as string);
  const MO = Number.parseFloat(params.param2 as string);
  const producaoEsperada = Number.parseFloat(params.param3 as string);
  const cropName = <string>params.param4;

   if(cropName == 'Soja'){
    return 0
  } 
  else {
    return (producaoEsperada / 1000) * extracao - MO * 2.5;
  }
};

const customFunctions: CustomFunctions = {
  extracao,
  exportacao,
  nivelCriticoP,
  capacidadeTampaoP,
  recomendacaoKCL,
  recomendacaoCalagem,
  recomendacaoS,
  necessidadeMicronutrienteN,
  gessagem,
  fertilizacaoS,
  nivel,
  necessidadeMicronutriente,
};

export { calculateValues };
