import { Expose } from 'class-transformer';

type StateName =
  | 'AC'
  | 'AL'
  | 'AM'
  | 'AP'
  | 'BA'
  | 'CE'
  | 'DF'
  | 'ES'
  | 'GO'
  | 'MA'
  | 'MG'
  | 'MS'
  | 'MT'
  | 'PA'
  | 'PB'
  | 'PE'
  | 'PI'
  | 'PR'
  | 'RJ'
  | 'RN'
  | 'RO'
  | 'RR'
  | 'RS'
  | 'SC'
  | 'SE'
  | 'SP'
  | 'TO';

class State {
  @Expose()
  public id: number;

  @Expose()
  public name: StateName;

  constructor(id: number, name: StateName) {
    this.id = id;
    this.name = name;
  }
}

export { State };
