import { Expose } from 'class-transformer';

class NivelCriticoP {
  @Expose()
  public id: number;

  @Expose()
  public argila: number;

  @Expose()
  public mehlich1: number;

  @Expose()
  public resina: number;

  constructor(id: number, argila: number, mehlich1: number, resina: number) {
    this.id = id;
    this.argila = argila;
    this.mehlich1 = mehlich1;
    this.resina = resina;
  }
}

export { NivelCriticoP };
