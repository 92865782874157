import { Expose } from 'class-transformer';
import { Model } from './model';
import { Moment } from 'moment';
import { Substance } from './substance';

type MeasurementLevelType = 'low' | 'mid' | 'appropriate' | 'high';
type MeasurementLevelRule =
  | 'less_than'
  | 'less_than_or_equal_to'
  | 'greater_than'
  | 'greater_than_or_equal_to';

class MeasurementLevel extends Model {
  @Expose()
  public level: MeasurementLevelType;

  @Expose({ name: 'value_1' })
  public value1: number;

  @Expose({ name: 'rule_value_1' })
  public ruleValue1: MeasurementLevelRule;

  @Expose({ name: 'value_2' })
  public value2: number | null;

  @Expose({ name: 'rule_value_2' })
  public ruleValue2: MeasurementLevelRule | null;

  @Expose()
  public extractor: string | null;

  @Expose({ name: 'substance_id' })
  public substanceId: number;

  @Expose()
  public substance: Substance;

  constructor(
    id: number | null,
    createdAt: Moment,
    updatedAt: Moment,
    deletedAt: Moment,
    level: MeasurementLevelType,
    value1: number,
    ruleValue1: MeasurementLevelRule,
    value2: number | null,
    ruleValue2: MeasurementLevelRule | null,
    extractor: string | null,
    substanceId: number,
    substance: Substance
  ) {
    super(id, createdAt, updatedAt, deletedAt);

    this.level = level;
    this.value1 = value1;
    this.ruleValue1 = ruleValue1;
    this.value2 = value2;
    this.ruleValue2 = ruleValue2;
    this.extractor = extractor;
    this.substanceId = substanceId;
    this.substance = substance;
  }

  public assert(value: number): boolean {
    const rule1Passed = this.checkRule(value, this.value1, this.ruleValue1);
    const rule2Passed =
      this.ruleValue2 !== null && this.value2 !== null
        ? this.checkRule(value, this.value2 || 0, this.ruleValue2)
        : true;

    return rule1Passed && rule2Passed;
  }

  private checkRule(value: number, valueToCompare: number, rule: MeasurementLevelRule) {
    switch (rule) {
      case 'less_than':
        return value < valueToCompare;

      case 'less_than_or_equal_to':
        return value <= valueToCompare;

      case 'greater_than':
        return value > valueToCompare;

      case 'greater_than_or_equal_to':
        return value >= valueToCompare;

      default:
        return false;
    }
  }
}

export { MeasurementLevel, MeasurementLevelType };
